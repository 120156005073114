import axios from '@/configs/http/internal-api-axios'
import { CURRENT_YEAR } from '@/utils/constants'
import { extendMasterRead } from '@/utils/function-utils'

const baseUrl = 'api/v1/company/department'
async function getListDepartment(perPage = 10, currentPage = 1, keySearch = '', isMaster = false) {
  let url = `${baseUrl}?perPage=${perPage}&currentPage=${currentPage}&keyword=${keySearch}`
  url = extendMasterRead(isMaster, url)
  const response = await axios.get(url).then((res) => {
    return res.data
  })
  return {
    items: response.data.items,
    total: response.data.total,
  }
}

async function getListUsers(id, perPage = 10, currentPage = 1, keySearch = '') {
  const url = `${baseUrl}/${id}/users?perPage=${perPage}&currentPage=${currentPage}&keyword=${keySearch}`
  const response = await axios.get(url).then((res) => {
    return res.data
  })
  return {
    items: response.data.items,
    total: response.data.total,
  }
}

async function getListApp(id, perPage = 10, currentPage = 1, keySearch = '') {
  const url = `api/v1/cost/apps/department/${id}?perPage=${perPage}&currentPage=${currentPage}&keyword=${keySearch}`
  const response = await axios.get(url).then((res) => {
    return res.data
  })
  return {
    items: response.data.items,
    total: response.data.total,
  }
}

async function getAvgCost(id, year = CURRENT_YEAR) {
  const url = `${baseUrl}/${id}/cost?year=${year}`
  const response = await axios.get(url)
  if (response.data) {
    return response.data.data.month_average_price
  }
}

async function create(formData) {
  const response = await axios
    .post(baseUrl, formData)
    .then((response) => {
      return {
        success: true,
        data: response.data,
      }
    })
    .catch((error) => {
      return {
        success: false,
        data: error.response.data.errors,
      }
    })
  return {
    success: response.success,
    data: response.data,
  }
}

async function getDataDetail(id, isMaster = false) {
  let url = `${baseUrl}/${id}`
  url = extendMasterRead(isMaster, url)
  const response = await axios.get(url).then((res) => {
    return res.data
  })
  return response.data
}

async function editItem(formData) {
  const url = `${baseUrl}/${formData.id}`
  const response = await axios
    .patch(url, formData)
    .then((res) => {
      return {
        success: true,
        data: res.data,
      }
    })
    .catch((err) => {
      return {
        success: false,
        data: err.response.data.errors,
      }
    })
  return {
    success: response.success,
    data: response.data,
  }
}
async function deleteItem(id) {
  if (id !== '') {
    const url = `${baseUrl}/${id}`
    return axios.delete(url).then((res) => {
      return res.data.success
    })
  }
  return false
}

export const departmentManagementService = {
  getListDepartment,
  create,
  editItem,
  deleteItem,
  getDataDetail,
  getListUsers,
  getListApp,
  getAvgCost,
}
